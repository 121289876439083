/**
 * The endpoint for the metadata file used to build the experiment
 * Version 1: https://backend.bgbehavior.com/gs01_task/gs01_metadata.json
 * Version 2:https://backend.bgbehavior.com/gs01_task/gs01_metadata_v2.json
 * export const METADATA_FILE_URL = "https://backend.bgbehavior.com/gs01_task/gs01_metadata_v2.json";
 */
export const METADATA_FILE_URL = "https://backend.bgbehavior.com/gs01_task/gs01_metadata_v3.json";

/** The image used for the driver's car */
export const DRIVER_FILE = "/assets/images/driver/defaultDriver.svg";

/** The image used for the target car when it is blurred out */
export const TARGET_BLURRED_FILE = "/assets/images/targetCars/target_blurred.svg";

/** The images used for the bomb countdown */
export const BOMB_IMAGES = [
  "/assets/images/hud/bomb_1.svg",
  "/assets/images/hud/bomb_2.svg",
  "/assets/images/hud/bomb_3.svg",
  "/assets/images/hud/bomb_4.svg",
  "/assets/images/hud/bomb_5.svg",
  "/assets/images/hud/bomb_6.svg",
  "/assets/images/hud/bomb_7.svg",
  "/assets/images/hud/bomb_8.svg",
];

/**
 * A mapping of keyboard keys to the lane that they belong to
 * NOTE @RobertGemmaJr: code 4 is the center lane which the user never chooses
 */
export const KEY_CODES = {
  s: 1,
  d: 2,
  f: 3,
  k: 5,
  l: 6,
  ";": 7,
};

export const LANE_COORDINATES = {
  1: 10,
  2: 75,
  3: 140,
  center: 205,
  5: 270,
  6: 335,
  7: 400,
};

/** The frame rate to animate the game at */
export const FRAME_RATE = 60;

/**
 * The height of the game board
 * NOTE: This is set directly in styles.css
 */
export const BOARD_HEIGHT = 550;

/**
 * The height of each highway divider
 * NOTE: This is set directly in styles.css
 */
export const DIVIDER_HEIGHT = 20;

/********** TESTING **********/

/** An object containing an empty timeline, used to skip a trial */
export const SKIP_TRIAL = { timeline: [] };
