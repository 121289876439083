import { h1 } from "../lib/markup/tags";
import { buildEndProcedure } from "./procedures/endProcedure";
import { buildMissionZero } from "./procedures/missionZero";
import { buildMissionOne } from "./procedures/missionOne";
import { buildMissionTwo } from "./procedures/missionTwo";
import { buildMissionThree } from "./procedures/missionThree";
import { buildMissionFour } from "./procedures/missionFour";
import { buildMissionFive } from "./procedures/missionFive";
import { buildStartProcedure } from "./procedures/startProcedure";

/**
 * Experiment-wide settings for jsPsych: https://www.jspsych.org/7.3/overview/experiment-options/
 * Note that Honeycomb combines these with other options required for Honeycomb to operate correctly
 */
export const jehliGearshiftTaskOptions = {
  show_progress_bar: false,
  // Called when every trial finishes
  on_trial_finish: function (data) {
    console.log(`Trial ${data.internal_node_id} just finished:`, data);
  },
  // Called when the experiment finishes
  on_finish: function (data) {
    console.log("The experiment has finished:", data);
    // Reload the page for another run-through of the experiment
    // window.location.reload();
  },
};

/**
 * This timeline builds the example reaction time task from the jsPsych tutorial.
 * Take a look at how the code here compares to the jsPsych documentation!
 * See the jsPsych documentation for more: https://www.jspsych.org/7.3/tutorials/rt-task/
 *
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Object} metadata The global metadata of the experiment
 * @param {string} studyID The ID of the study that was just logged into
 * @param {string} participantID The ID of the participant that was just logged in
 * @param {string} sessionID The ID of the current session that was just logged in
 * @returns {Object} A jsPsych timeline object
 */

export async function buildJehliGearshiftTaskTimeline(
  jsPsych,
  metadata,
  studyID,
  participantID,
  sessionID
) {
  // Build the start procedure
  const startProcedure = buildStartProcedure(jsPsych);

  // Build the mission procedures
  const missionZero = await buildMissionZero(jsPsych, metadata);
  const missionOne = await buildMissionOne(jsPsych, metadata);
  const missionTwo = await buildMissionTwo(jsPsych, metadata);
  const missionThree = await buildMissionThree(jsPsych, metadata);
  const missionFour = await buildMissionFour(jsPsych, metadata);
  const missionFive = await buildMissionFive(jsPsych, metadata);

  // Build the end procedure
  const endProcedure = buildEndProcedure(jsPsych, metadata, studyID, participantID, sessionID);

  // TODO: What's the best place to put this? How to get it in the data?
  const globalKeypresses = [];
  jsPsych.pluginAPI.getKeyboardResponse({
    callback_function: (keypress) => {
      globalKeypresses.push(keypress);

      // End the experiment
      if (jsPsych.pluginAPI.compareKeys(keypress.key, "q")) {
        jsPsych.data.addProperties({ experimentCompleted: false }); // TODO: Add this as "true" in the final trial of the experiment
        jsPsych.endExperiment(h1("Quit! Thank you for your participation"), {
          userCancelledExperiment: true,
        });
      }
    },
    persist: true,
    allow_held_key: true,
  });

  const timeline = [
    startProcedure,
    missionZero,
    missionOne,
    missionTwo,
    missionThree,
    missionFour,
    missionFive,
    endProcedure,
  ];
  // const timeline = [missionOne]; // TEMP: Just test mission 1
  // const timeline = [missionTwo]; // TEMP: Just test mission 2
  // const timeline = [missionThree]; // TEMP: Just test mission 3
  // const timeline = [missionFour]; // TEMP: Just test mission 4
  // const timeline = [missionFive]; // TEMP: Just test mission 5

  return timeline;
}
